import React from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import NewsCards from "containers/News/NewsCards/NewsCards"

const News = () => {
  const breadcrumbsData = [
    {
      title: "Startseite",
      link: "/",
    },
    "Neuigkeiten",
  ]
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Neuigkeiten">
      <Hr mb={4} mt={6} />
      <Font42 mb={4}>Neuigkeiten</Font42>
      <NewsCards />
    </Layout>
  )
}

export default News
